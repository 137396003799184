import { ChangeEvent, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/auth-provider'
import { AuthLayout } from '../../layouts/AuthLayout'

export default function Login() {
  const [inputs, setInputs] = useState({ email: '', password: '' })
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { login } = useAuth()
  const navigate = useNavigate()
  const { state } = useLocation()

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInputs((values) => ({ ...values, [target.name]: target.value }))
  }

  const handleSubmit = async (event: { preventDefault: () => any }) => {
    setErrorMessage('')
    event.preventDefault()
    try {
      await login(inputs.email, inputs.password)
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
        console.error(error)
      }
    }
  }

  useEffect(() => {
    return () => {
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h3 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Sign in to the platform
            </h3>
            {state && !state.userConfirmed && (
              <div
                className="p-4 mb-4 text-sm  bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
                role="alert"
              >
                Just one last step. Please check your email for the verification link to complete your sign-up. If you
                don't see it in your inbox, please check your spam folder. Happy helming!
              </div>
            )}
            <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5"
                  placeholder="name@example.com"
                  required
                  onChange={handleChange}
                  value={inputs.email}
                />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5 "
                  required
                  onChange={handleChange}
                  value={inputs.password || ''}
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-400 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Sign in
              </button>

              {errorMessage && (
                <div
                  className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                  role="alert"
                >
                  <span className="font-medium">{errorMessage}</span>
                </div>
              )}

              <div className="flex items-center justify-between">
                <Link to="/forgot-password" className="text-sm font-medium text-primary-400 hover:underline ">
                  Forgot password?
                </Link>
              </div>

              <p className="text-sm font-light text-gray-500 ">
                Don't have an account yet?{' '}
                <a
                  href="https://www.helmur.com/helmur-waitlist"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-primary-400 hover:underline"
                >
                  Sign up
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
