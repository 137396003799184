"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = forEachItemCombination;
function forEachItemCombination(breakdowns, iterator) {
    // Helper function to recursively iterate and build combinations
    function generateCombinations(index, currentCombination) {
        // Base case: If the current index is equal to the input length,
        // we've reached the end and can call the callback with the current combination.
        if (index === breakdowns.length) {
            iterator(currentCombination);
            return;
        }
        // Recursive case: Iterate over the items in the current index
        const currentItemArray = breakdowns[index].breakdownItems;
        currentItemArray.forEach((item) => {
            // For each item, add its id to the current combination and recurse
            generateCombinations(index + 1, currentCombination + item.uid);
        });
    }
    // Start the recursion with the first array and an empty string for the combination
    generateCombinations(0, '');
}
