"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = generateContributionsTree;
function generateContributionsTree({ primaryBreakdown, secondaryBreakdown, tertiaryBreakdown, }) {
    const contributions = {};
    if (primaryBreakdown) {
        contributions.demandPlan = {
            level: 'demandPlan',
            breakdowns: [],
            children: [],
            visited: false,
        };
        contributions.P = {
            level: 'P',
            breakdowns: [primaryBreakdown],
            parents: [contributions.demandPlan],
            visited: false,
        };
        contributions.demandPlan.children?.push(contributions.P);
        if (secondaryBreakdown) {
            contributions.S = {
                level: 'S',
                breakdowns: [secondaryBreakdown],
                parents: [contributions.demandPlan],
                visited: false,
            };
            contributions.PxS = {
                level: 'PxS',
                breakdowns: [primaryBreakdown, secondaryBreakdown],
                visited: false,
                parents: [contributions.P, contributions.S],
            };
            contributions.demandPlan.children?.push(contributions.S);
            contributions.P.children = [contributions.PxS];
            contributions.S.children = [contributions.PxS];
            if (tertiaryBreakdown) {
                contributions.T = {
                    level: 'T',
                    breakdowns: [tertiaryBreakdown],
                    parents: [contributions.demandPlan],
                    visited: false,
                };
                contributions.PxT = {
                    level: 'PxT',
                    breakdowns: [primaryBreakdown, tertiaryBreakdown],
                    visited: false,
                    parents: [contributions.P, contributions.T],
                };
                contributions.SxT = {
                    level: 'SxT',
                    breakdowns: [secondaryBreakdown, tertiaryBreakdown],
                    visited: false,
                    parents: [contributions.S, contributions.T],
                };
                contributions.PxSxT = {
                    level: 'PxSxT',
                    breakdowns: [primaryBreakdown, secondaryBreakdown, tertiaryBreakdown],
                    visited: false,
                    parents: [contributions.PxS, contributions.PxT, contributions.SxT],
                };
                contributions.demandPlan.children?.push(contributions.T);
                contributions.P.children.push(contributions.PxT);
                contributions.S.children.push(contributions.SxT);
                contributions.T.children = [contributions.PxT, contributions.SxT];
                contributions.PxS.children = [contributions.PxSxT];
                contributions.PxT.children = [contributions.PxSxT];
                contributions.SxT.children = [contributions.PxSxT];
            }
        }
    }
    return contributions;
}
