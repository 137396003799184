"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBreakdownFormField = exports.isString = void 0;
const isString = (x) => {
    return !!x;
};
exports.isString = isString;
const isBreakdownFormField = (x) => {
    return !!x;
};
exports.isBreakdownFormField = isBreakdownFormField;
