"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValues = exports.KEY_COORDINATE_LENGTH = exports.lastDayOfYear = exports.firstDayOfYear = void 0;
const date_fns_1 = require("date-fns");
const uuid_1 = require("uuid");
const currentDate = new Date(); // Get the current date
const currentYear = currentDate.getFullYear(); // Get the current year
exports.firstDayOfYear = new Date(currentYear, 0, 1); // Set the date to the first day of the year
exports.lastDayOfYear = (0, date_fns_1.endOfYear)(new Date(currentYear, 0, 1)); // Set the date to the first day of the year
exports.KEY_COORDINATE_LENGTH = (0, uuid_1.v4)().length;
const DEFAULT_TARGET = 5000000;
const BREAKDOWN_ITEMS_IDS = [(0, uuid_1.v4)(), (0, uuid_1.v4)(), (0, uuid_1.v4)()];
const FUNNEL_IDS = [(0, uuid_1.v4)(), (0, uuid_1.v4)(), (0, uuid_1.v4)()];
exports.defaultValues = {
    name: 'Next Year',
    description: 'Revenue plan for next year',
    startDate: (0, date_fns_1.add)(exports.firstDayOfYear, { years: 1 }),
    endDate: (0, date_fns_1.add)(exports.lastDayOfYear, { years: 1 }),
    targetName: 'Revenue',
    targetValue: DEFAULT_TARGET,
    status: 'active',
    monthlySpreads: [
        {
            month: 1,
            percentage: 8,
        },
        {
            month: 2,
            percentage: 8,
        },
        {
            month: 3,
            percentage: 8,
        },
        {
            month: 4,
            percentage: 8,
        },
        {
            month: 5,
            percentage: 8,
        },
        {
            month: 6,
            percentage: 8,
        },
        {
            month: 7,
            percentage: 8,
        },
        {
            month: 8,
            percentage: 8,
        },
        {
            month: 9,
            percentage: 8,
        },
        {
            month: 10,
            percentage: 8,
        },
        {
            month: 11,
            percentage: 10,
        },
        {
            month: 12,
            percentage: 10,
        },
    ],
    breakdowns: [
        {
            uid: (0, uuid_1.v4)(),
            name: 'Revenue Type',
            breakdownType: 'primary',
            breakdownItems: [
                {
                    uid: BREAKDOWN_ITEMS_IDS[0],
                    name: 'Acquisition',
                },
                {
                    uid: BREAKDOWN_ITEMS_IDS[1],
                    name: 'Expansion - New',
                },
                {
                    uid: BREAKDOWN_ITEMS_IDS[2],
                    name: 'Channel',
                },
            ],
        },
    ],
    spreads: {
        [BREAKDOWN_ITEMS_IDS[0]]: {
            spreadPercent: 50,
            value: DEFAULT_TARGET * 0.5,
        },
        [BREAKDOWN_ITEMS_IDS[1]]: {
            spreadPercent: 35,
            value: DEFAULT_TARGET * 0.35,
        },
        [BREAKDOWN_ITEMS_IDS[2]]: {
            spreadPercent: 15,
            value: DEFAULT_TARGET * 0.15,
        },
    },
    funnels: [
        {
            name: 'Inbound',
            uid: FUNNEL_IDS[0],
            funnelItems: [
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'Lead',
                    description: 'Any name from any inbound channel (not junk)',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'MQL',
                    description: 'Trial/Contact Us Lead + Valid Email + within TAM',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SAL',
                    description: 'Not junk + within TAM',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQL',
                    description: 'Meets ICP + Any two B.A.N.T.',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQO',
                    description: '2 BANT + Pain Identified + T < 60 Days',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'DEAL',
                    description: 'Closed Won Inbound Deal',
                },
            ],
        },
        {
            name: 'Outbound',
            uid: FUNNEL_IDS[1],
            funnelItems: [
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'Lead',
                    description: 'Sales Generated from within ICP/Customer',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQL',
                    description: 'Meets ICP + Any two B.A.N.T.',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQO',
                    description: '2 BANT + Pain Identified + T < 60 Days',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'DEAL',
                    description: 'Closed Won Outbound Deal',
                },
            ],
        },
        {
            name: 'Partner',
            uid: FUNNEL_IDS[2],
            funnelItems: [
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'Lead',
                    description: 'Partner Referred Account',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQL',
                    description: 'Meets ICP + Valid Partner Account + Any two B.A.N.T.',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'SQO',
                    description: '2 BANT + Pain Identified + T < 90 Days',
                },
                {
                    uid: (0, uuid_1.v4)(),
                    name: 'DEAL',
                    description: 'Closed Won Partner Deal',
                },
            ],
        },
    ],
    funnelSpreads: {},
    selectedFunnelSpread: 'demandPlan',
};
