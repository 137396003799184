"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formDataToPayload = void 0;
const utils_1 = require("../../utils");
function decomposeKey(input, size) {
    const result = [];
    for (let i = 0; i < input.length; i += size) {
        result.push(input.substring(i, i + size));
    }
    return result;
}
const formDataToPayloadSpreads = (formData) => {
    const breakdownItemsMapping = {};
    formData.breakdowns.forEach((breakdown, index) => {
        return breakdown.breakdownItems.forEach((breakdownItem, breakdownItemIndex) => {
            breakdownItemsMapping[breakdownItem.uid] = {
                id: breakdownItem.id,
                breakdownOrder: index,
                breakdownItemOrder: breakdownItemIndex,
                ...breakdownItem,
            };
        });
    });
    const funnelMapping = formData.funnels.reduce((result, funnel, index) => {
        return {
            ...result,
            [funnel.uid]: {
                demandFunnelOrder: index,
                ...funnel,
                funnelItems: funnel.funnelItems.reduce((funnelItemsResult, funnelItem, funnelItemIndex) => {
                    return {
                        ...funnelItemsResult,
                        [funnelItem.uid]: {
                            demandFunnelItemOrder: funnelItemIndex,
                            ...funnelItem,
                        },
                    };
                }, {}),
            },
        };
    }, {});
    const spreadKeys = Object.keys(formData.spreads);
    const payloadSpreads = spreadKeys
        .map((key) => {
        const keys = decomposeKey(key, utils_1.KEY_COORDINATE_LENGTH);
        if (!formData.funnelSpreads || !formData.funnelSpreads[key])
            return null;
        const funnelSpreads = Object.keys(formData.funnelSpreads[key]).map((funnelKey) => {
            const funnel = funnelMapping[funnelKey];
            const funnelSpread = formData.funnelSpreads[key][funnelKey];
            return {
                id: funnelSpread.id,
                demandFunnelOrder: funnel.demandFunnelOrder,
                spreadPercent: funnelSpread.spreadPercent,
                averageDealSize: funnelSpread.averageDealSize || 0,
                value: funnelSpread.value,
                demandFunnelSpreadItems: Object.keys(funnelSpread.assumptions).map((assumptionKey) => {
                    return {
                        id: funnelSpread.assumptions[assumptionKey].id,
                        demandFunnelItemOrder: funnel.funnelItems[assumptionKey].demandFunnelItemOrder,
                        conversion: funnelSpread.assumptions[assumptionKey].conversion,
                        count: funnelSpread.assumptions[assumptionKey].count,
                    };
                }),
            };
        });
        return {
            id: formData.spreads[key].id,
            spreadPercent: formData.spreads[key].spreadPercent,
            value: formData.spreads[key].value,
            demandSpreadParticipants: keys.map((k) => {
                return {
                    id: breakdownItemsMapping[k]?.id,
                    demandBreakdownOrder: breakdownItemsMapping[k].breakdownOrder,
                    demandBreakdownItemOrder: breakdownItemsMapping[k].breakdownItemOrder,
                };
            }),
            demandFunnelSpreads: funnelSpreads,
        };
    })
        .filter((value) => !!value);
    if (formData.funnelSpreads.demandPlan) {
        // Add the demandPlan level spreads
        payloadSpreads.push({
            id: 'demandPlan',
            spreadPercent: 0,
            value: 0,
            demandSpreadParticipants: [],
            demandFunnelSpreads: formData.funnels.map((funnel) => {
                const mappedFunnel = funnelMapping[funnel.uid];
                const funnelSpread = formData.funnelSpreads.demandPlan[funnel.uid];
                return {
                    id: funnelSpread.id,
                    demandFunnelOrder: mappedFunnel.demandFunnelOrder,
                    spreadPercent: funnelSpread.spreadPercent,
                    averageDealSize: funnelSpread.averageDealSize,
                    value: funnelSpread.value,
                    demandFunnelSpreadItems: Object.keys(funnelSpread.assumptions).map((assumptionKey) => {
                        return {
                            id: funnelSpread.assumptions[assumptionKey].id,
                            demandFunnelItemOrder: mappedFunnel.funnelItems[assumptionKey].demandFunnelItemOrder,
                            conversion: funnelSpread.assumptions[assumptionKey].conversion,
                            count: funnelSpread.assumptions[assumptionKey].count,
                        };
                    }),
                };
            }),
        });
    }
    return payloadSpreads;
};
const formDataToPayload = (formData) => {
    return {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        startDate: formData.startDate,
        endDate: formData.endDate,
        status: formData.status,
        targetName: formData.targetName,
        total: formData.targetValue,
        selectedFunnelHierarchy: formData.selectedFunnelSpread,
        demandMonthlySpreads: formData.monthlySpreads.map((spread) => {
            return {
                id: spread.id,
                month: spread.month,
                percentage: spread.percentage,
            };
        }),
        demandBreakdowns: formData.breakdowns.map((breakdown, breakdownIndex) => {
            return {
                id: breakdown.id,
                name: breakdown.name,
                order: breakdownIndex,
                breakdownType: breakdown.breakdownType,
                demandBreakdownItems: breakdown.breakdownItems.map((breakdownItem, index) => {
                    return {
                        id: breakdownItem.id,
                        name: breakdownItem.name,
                        order: index,
                    };
                }),
            };
        }),
        demandFunnels: formData.funnels.map((funnel, funnelIndex) => {
            return {
                id: funnel.id,
                name: funnel.name,
                order: funnelIndex,
                demandFunnelItems: funnel.funnelItems.map((funnelItem, index) => {
                    return {
                        id: funnelItem.id,
                        name: funnelItem.name,
                        description: funnelItem.description,
                        order: index,
                    };
                }),
            };
        }),
        demandSpreads: formDataToPayloadSpreads(formData),
    };
};
exports.formDataToPayload = formDataToPayload;
