import { omit } from 'lodash'
import CustomErrorHandler from '../../common/helpers/error'
import deepMerge from './deepMerge'

class Requester {
  private static instance: Requester

  readonly apiUrl = import.meta.env.VITE_API_URL

  private _config: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): Requester {
    if (!Requester.instance) {
      Requester.instance = new Requester()
    }

    return Requester.instance
  }

  public getConfig() {
    return this._config
  }

  public setConfig(config: RequestInit) {
    this._config = deepMerge(this._config, config)
  }

  get = async <T>(path: string, config?: RequestInit): Promise<T> => {
    const init = { method: 'GET', ...config }
    return await this.http<T>(path, init)
  }

  post = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: 'POST', body: JSON.stringify(body), ...config }
    return await this.http<U>(path, init)
  }

  postFormData = async <U>(path: string, body: FormData, config?: RequestInit): Promise<U> => {
    const init = { method: 'POST', body, ...config }
    return await this.http<U>(path, init, true)
  }

  put = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: 'PUT', body: JSON.stringify(body), ...config }
    return await this.http<U>(path, init)
  }

  patch = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: 'PATCH', body: JSON.stringify(body), ...config }
    return await this.http<U>(path, init)
  }

  delete = async <T>(path: string, config?: RequestInit): Promise<T> => {
    const init = { method: 'DELETE', ...config }
    return await this.http<T>(path, init)
  }

  private http = async <T>(path: string, config: RequestInit, omitContentType = false): Promise<T> => {
    const headers = {
      ...(omit(this._config.headers, omitContentType ? 'Content-Type' : '') || {}),
      ...(config.headers || {}),
    }
    const request = new Request(`${this.apiUrl}${path}`, {
      ...this._config,
      ...config,
      headers: headers as Record<string, string>,
    })
    const response = await fetch(request)

    if (!response.ok) {
      const error = await response.json()
      console.warn(response)
      console.warn(error)
      throw new CustomErrorHandler(error.message || response.statusText, response.status, [], '')
    }

    // may error if there is nobody, return empty array
    return response.json().catch(() => ({}))
  }
}

export const ApiService = Requester.getInstance()
