"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveInheritanceForFunnelSpreads = resolveInheritanceForFunnelSpreads;
const lodash_1 = require("lodash");
function cloneDeepWithoutFunnelSpreadIds({ funnelSpreads, funnelSpread, key, }) {
    const newFunnelSpread = (0, lodash_1.cloneDeep)(funnelSpread);
    Object.keys(newFunnelSpread).forEach((funnelUid) => {
        if (funnelSpreads[key]) {
            // preserve the old funnel spread id
            newFunnelSpread[funnelUid].id = funnelSpreads[key][funnelUid].id;
            // preserve the old funnel spread items ids
            Object.keys(funnelSpreads[key][funnelUid].assumptions).forEach((assumptionKey) => {
                if (funnelSpreads[key][funnelUid].assumptions[assumptionKey].id) {
                    newFunnelSpread[funnelUid].assumptions[assumptionKey].id =
                        funnelSpreads[key][funnelUid].assumptions[assumptionKey].id;
                }
            });
        }
        else {
            delete newFunnelSpread[funnelUid].id;
        }
    });
    return newFunnelSpread;
}
// WARNING: Modifies the `funnelSpreads` argument.
// WARNING: Recursive function.
function resolveInheritanceForFunnelSpreads({ spreadsLevel, spreads, funnelSpreads, funnels, breakdowns, contributionsTree, }) {
    const startingNode = contributionsTree[spreadsLevel];
    if (spreadsLevel === 'demandPlan') {
        startingNode?.children?.forEach((contributionLevel) => {
            contributionLevel.breakdowns.forEach((breakdown) => {
                breakdown.breakdownItems.forEach((breakdownItem) => {
                    funnelSpreads[breakdownItem.uid] = cloneDeepWithoutFunnelSpreadIds({
                        funnelSpreads,
                        funnelSpread: funnelSpreads.demandPlan,
                        key: breakdownItem.uid,
                    });
                    // Calculate the funnel spreads according to the new total
                    funnels.forEach((funnel) => {
                        funnelSpreads[breakdownItem.uid][funnel.uid].value = Math.round(spreads[breakdownItem.uid].value * (funnelSpreads[breakdownItem.uid][funnel.uid].spreadPercent / 100));
                    });
                });
            });
            contributionLevel.visited = true;
            resolveInheritanceForFunnelSpreads({
                breakdowns,
                funnels,
                funnelSpreads,
                spreads,
                spreadsLevel: contributionLevel.level,
                contributionsTree,
            });
        });
    }
    else {
        const selectedBreakdowns = spreadsLevel?.split('x');
        if (selectedBreakdowns.length === 1) {
            // The funnel contribution for the selected level should be already in `funnelSpreads`.
            startingNode.visited = true;
            startingNode.children?.forEach((contributionLevel) => {
                if (contributionLevel.visited)
                    return;
                if (contributionLevel.breakdowns.length === 2) {
                    contributionLevel.breakdowns[0].breakdownItems.forEach((breakdownItem1) => {
                        contributionLevel.breakdowns[1].breakdownItems.forEach((breakdownItem2) => {
                            const key = `${breakdownItem1.uid}${breakdownItem2.uid}`;
                            // funnelSpreads[breakdownItem1.uid] or funnelSpreads[breakdownItem2.uid] should be available,
                            // because we are "inheriting" from a parent.
                            funnelSpreads[key] = cloneDeepWithoutFunnelSpreadIds({
                                funnelSpreads,
                                funnelSpread: funnelSpreads[breakdownItem2.uid] || funnelSpreads[breakdownItem1.uid],
                                key,
                            });
                            // Calculate the funnel spreads according to the new total
                            funnels.forEach((funnel) => {
                                funnelSpreads[key][funnel.uid].value = Math.round(spreads[key].value * (funnelSpreads[key][funnel.uid].spreadPercent / 100));
                            });
                        });
                    });
                }
                contributionLevel.visited = true;
                resolveInheritanceForFunnelSpreads({
                    breakdowns,
                    funnels,
                    funnelSpreads,
                    spreads,
                    spreadsLevel: contributionLevel.level,
                    contributionsTree,
                });
            });
        }
        else if (selectedBreakdowns.length === 2) {
            startingNode?.children?.forEach((contributionLevel) => {
                if (contributionLevel.visited)
                    return;
                if (contributionLevel.breakdowns.length === 3) {
                    contributionLevel.breakdowns[0].breakdownItems.forEach((breakdownItem1) => {
                        contributionLevel.breakdowns[1].breakdownItems.forEach((breakdownItem2) => {
                            contributionLevel.breakdowns[2].breakdownItems.forEach((breakdownItem3) => {
                                const key = `${breakdownItem1.uid}${breakdownItem2.uid}${breakdownItem3.uid}`;
                                funnelSpreads[key] = cloneDeepWithoutFunnelSpreadIds({
                                    funnelSpreads,
                                    funnelSpread: funnelSpreads[`${breakdownItem2.uid}${breakdownItem3.uid}`] ||
                                        funnelSpreads[`${breakdownItem1.uid}${breakdownItem2.uid}`] ||
                                        funnelSpreads[`${breakdownItem1.uid}${breakdownItem3.uid}`],
                                    key,
                                });
                                // Calculate the funnel spreads according to the new total
                                funnels.forEach((funnel) => {
                                    funnelSpreads[key][funnel.uid].value = Math.round(spreads[key].value * (funnelSpreads[key][funnel.uid].spreadPercent / 100));
                                });
                            });
                        });
                    });
                }
                contributionLevel.visited = true;
            });
        }
    }
}
