import { useMutation, useQuery } from '@tanstack/react-query'
import { confirmInvite, getInvite } from 'common/services/auth'

export enum CONFIRM_INVITE_QUERY_KEYS {
  confirmInvite = 'confirmInvite',
}

const useConfirmInvite = (token: string) => {
  const inviteQuery = useQuery({
    queryKey: [CONFIRM_INVITE_QUERY_KEYS.confirmInvite, token],
    queryFn: getInvite,
    refetchOnWindowFocus: false,
    enabled: !!token,
  })

  const confirmInviteMutation = useMutation({
    mutationFn: confirmInvite,
  })

  return {
    inviteQuery,
    confirmInviteMutation,
  }
}

export default useConfirmInvite
