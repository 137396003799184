import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/auth-provider'
import { Loader } from '../common/components/Loader'

export function GuestGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated, isInitialized } = useAuth()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  if (!isInitialized) {
    return <Loader />
  }

  return <>{children}</>
}
