"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveInheritanceForFunnelAssumptions = resolveInheritanceForFunnelAssumptions;
const generateFunnelSpreads_1 = require("../funnel-spread-calculator/generateFunnelSpreads");
// WARNING: Modifies the `funnelSpreads` argument.
function calculateFunnelAssumptionForLevel({ funnels, startingSpread, funnelSpreads, key, }) {
    funnels.forEach((funnel) => {
        const startingSpreadFunnel = startingSpread[funnel.uid];
        funnelSpreads[key][funnel.uid].averageDealSize = startingSpreadFunnel.averageDealSize;
        const funnelAssumptionItems = funnel.funnelItems.map((item) => {
            const funnelAssumptionItem = funnelSpreads[key][funnel.uid].assumptions[item.uid];
            return {
                id: funnelAssumptionItem?.id,
                uid: item.uid,
                count: 0, // doesn't matter, it will be calculated later
                conversion: startingSpreadFunnel.assumptions[item.uid].conversion,
            };
        });
        const newFunnelAssumption = (0, generateFunnelSpreads_1.calculateFunnelAssumptionsFromConversion)({
            total: funnelSpreads[key][funnel.uid].value,
            averageDealSize: startingSpreadFunnel.averageDealSize,
            funnelAssumptionItems,
        });
        funnelSpreads[key][funnel.uid].assumptions = newFunnelAssumption;
    });
}
// WARNING: Modifies the `funnelSpreads` argument.
// WARNING: Recursive function.
function resolveInheritanceForFunnelAssumptions({ spreadsLevel, spreads, funnelSpreads, funnels, breakdowns, contributionsTree, }) {
    const startingNode = contributionsTree[spreadsLevel];
    if (spreadsLevel === 'demandPlan') {
        startingNode.children?.forEach((contributionLevel) => {
            contributionLevel.breakdowns.forEach((breakdown) => {
                breakdown.breakdownItems.forEach((breakdownItem) => {
                    const startingSpread = funnelSpreads.demandPlan;
                    calculateFunnelAssumptionForLevel({ funnels, startingSpread, funnelSpreads, key: breakdownItem.uid });
                });
            });
            contributionLevel.visited = true;
            resolveInheritanceForFunnelAssumptions({
                breakdowns,
                funnels,
                funnelSpreads,
                spreads,
                spreadsLevel: contributionLevel.level,
                contributionsTree,
            });
        });
    }
    else {
        const selectedBreakdowns = spreadsLevel?.split('x');
        if (selectedBreakdowns.length === 1) {
            // The funnel contribution for the selected level should be already in `funnelSpreads`.
            startingNode.visited = true;
            startingNode.children?.forEach((contributionLevel) => {
                if (contributionLevel.visited)
                    return;
                if (contributionLevel.breakdowns.length === 2) {
                    contributionLevel.breakdowns[0].breakdownItems.forEach((breakdownItem1) => {
                        contributionLevel.breakdowns[1].breakdownItems.forEach((breakdownItem2) => {
                            const key = `${breakdownItem1.uid}${breakdownItem2.uid}`;
                            // funnelSpreads[breakdownItem1.uid] or funnelSpreads[breakdownItem2.uid] should be available,
                            // because we are "inheriting" from a parent.
                            let startingSpread = funnelSpreads[breakdownItem2.uid] || funnelSpreads[breakdownItem1.uid];
                            // make sure we have assumptions for the startingSpread
                            if (funnelSpreads[breakdownItem2.uid] &&
                                Object.values(funnelSpreads[breakdownItem2.uid]).every((v) => Object.values(v.assumptions).length > 0)) {
                                startingSpread = funnelSpreads[breakdownItem2.uid];
                            }
                            else if (funnelSpreads[breakdownItem1.uid] &&
                                Object.values(funnelSpreads[breakdownItem1.uid]).every((v) => Object.values(v.assumptions).length > 0)) {
                                startingSpread = funnelSpreads[breakdownItem1.uid];
                            }
                            calculateFunnelAssumptionForLevel({ funnels, startingSpread, funnelSpreads, key });
                        });
                    });
                }
                contributionLevel.visited = true;
                resolveInheritanceForFunnelAssumptions({
                    breakdowns,
                    funnels,
                    funnelSpreads,
                    spreads,
                    spreadsLevel: contributionLevel.level,
                    contributionsTree,
                });
            });
        }
        else if (selectedBreakdowns.length === 2) {
            startingNode.children?.forEach((contributionLevel) => {
                if (contributionLevel.visited)
                    return;
                if (contributionLevel.breakdowns.length === 3) {
                    contributionLevel.breakdowns[0].breakdownItems.forEach((breakdownItem1) => {
                        contributionLevel.breakdowns[1].breakdownItems.forEach((breakdownItem2) => {
                            contributionLevel.breakdowns[2].breakdownItems.forEach((breakdownItem3) => {
                                const key = `${breakdownItem1.uid}${breakdownItem2.uid}${breakdownItem3.uid}`;
                                // funnelSpreads[breakdownItem1.uid] or funnelSpreads[breakdownItem2.uid] should be available,
                                // because we are "inheriting" from a parent.
                                let startingSpread = funnelSpreads[`${breakdownItem2.uid}${breakdownItem3.uid}`] ||
                                    funnelSpreads[`${breakdownItem1.uid}${breakdownItem2.uid}`] ||
                                    funnelSpreads[`${breakdownItem1.uid}${breakdownItem3.uid}`];
                                // make sure we have assumptions for the startingSpread
                                if (funnelSpreads[`${breakdownItem2.uid}${breakdownItem3.uid}`] &&
                                    Object.values(funnelSpreads[`${breakdownItem2.uid}${breakdownItem3.uid}`]).every((v) => Object.values(v.assumptions).length > 0)) {
                                    startingSpread = funnelSpreads[`${breakdownItem2.uid}${breakdownItem3.uid}`];
                                }
                                else if (funnelSpreads[`${breakdownItem1.uid}${breakdownItem2.uid}`] &&
                                    Object.values(funnelSpreads[`${breakdownItem1.uid}${breakdownItem2.uid}`]).every((v) => Object.values(v.assumptions).length > 0)) {
                                    startingSpread = funnelSpreads[`${breakdownItem1.uid}${breakdownItem2.uid}`];
                                }
                                else if (funnelSpreads[`${breakdownItem1.uid}${breakdownItem3.uid}`] &&
                                    Object.values(funnelSpreads[`${breakdownItem1.uid}${breakdownItem3.uid}`]).every((v) => Object.values(v.assumptions).length > 0)) {
                                    startingSpread = funnelSpreads[`${breakdownItem1.uid}${breakdownItem3.uid}`];
                                }
                                calculateFunnelAssumptionForLevel({ funnels, startingSpread, funnelSpreads, key });
                            });
                        });
                    });
                }
                contributionLevel.visited = true;
            });
        }
    }
}
