"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = splitBreakdowns;
function splitBreakdowns(breakdowns) {
    let primaryBreakdown;
    let secondaryBreakdown;
    let tertiaryBreakdown;
    breakdowns.forEach((breakdown) => {
        if (breakdown.breakdownType === 'primary') {
            primaryBreakdown = breakdown;
        }
        else if (breakdown.breakdownType === 'secondary') {
            secondaryBreakdown = breakdown;
        }
        else if (breakdown.breakdownType === 'tertiary') {
            tertiaryBreakdown = breakdown;
        }
    });
    return {
        primaryBreakdown,
        secondaryBreakdown,
        tertiaryBreakdown,
    };
}
